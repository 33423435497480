
export default {
  props: {
    blockTitles: {
      type: [Array, Object],
      required: true,
    },
    displayAllDay: {
      type: Boolean,
      default: false,
    },
    resultDisplayTime: {
      type: [Number, String],
      default: 180, // 3 hours after kickoff
    },
    eventOverride: {
      type: Boolean,
      default: false,
    },
    eventInfo: {
      type: [Boolean, Object],
      default: () => ({}),
    },
    team: {
      type: Object,
      default: () => ({}),
    },
    refreshTime: {
      type: Number,
      default: 20000, // 20 seconds
    },
    matchCountdown: {
      type: Boolean,
      default: true,
    },
    liveFeed: {
      type: Object,
      default: () => ({}),
    },
    hideLiveFeed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      displayType: `fixture`,
      latestResult: null, // Store result here to check state
      activeFixture: null,
      abandoned: false,
      postponed: false,
      interval: null,
      refresh: false, // If component is refreshing
    };
  },
  computed: {
    blockTitle () {
      this.$emit(`tab-title`, this.blockTitles[this.displayType]);
      if (this.activeFixture) {
        const homeTeam = this.getTeam(this.activeFixture, `Home`);
        const awayTeam = this.getTeam(this.activeFixture, `Away`);

        if (this.activeFixture.state.displayName === `Half Time`) {
          return `Half Time: ${homeTeam.team.teamNames[0].displayName} ${homeTeam.score} - ${awayTeam.score} ${awayTeam.team.teamNames[0].displayName}`;
        }

        let scoreString = ``;
        switch (this.displayType) {
        case `result`:
          scoreString = `${homeTeam.team.teamNames[0].displayName} ${homeTeam.score} - ${awayTeam.score} ${awayTeam.team.teamNames[0].displayName}`;
          return `${this.blockTitles[this.displayType]}: ${scoreString}`;

        case `live`:
          scoreString = `${homeTeam.team.teamNames[0].displayName} ${homeTeam.score} ${awayTeam.team.teamNames[0].displayName} ${awayTeam.score} `;
          return `${this.blockTitles[this.displayType]}: ${scoreString}`;

        case `fixture`:
          scoreString = `${homeTeam.team.teamNames[0].displayName} v ${awayTeam.team.teamNames[0].displayName}`;
          return `${this.blockTitles[this.displayType]}: ${scoreString}`;

        default:
          return this.blockTitles[this.displayType];
        }
      }
      return this.blockTitles[this.displayType];
    },
    currentComponent () {
      // Get component name from display type dynamically rather than a load of v-ifs
      const label = this.capitalise(this.displayType);
      return `NextMatch${label}`;
    },
  },
  watch: {
    refresh () {
      if (this.refresh && this.refreshTime > 0) {
        this.interval = setInterval(() => {
          this.getLatestResult();
        }, this.refreshTime);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  mounted () {
    if (this.eventOverride) {
      this.displayType = `event`;
    } else {
      this.getLatestResult();
    }
  },
  methods: {
    async getLatestResult () {
      const params = {
        include: [
          `fixture.stage.season.competition`,
          `fixture.venue.venueNames`,
          `fixture.state`,
          `fixture.fixtureTeams.team.images`,
          `fixture.fixtureTeams.substitutions.state`,
          `fixture.fixtureTeams.substitutions.lineupOn.player`,
          `fixture.fixtureTeams.substitutions.lineupOff.player`,
          `fixture.fixtureBroadcasts.broadcaster.images`,
          `fixture.fixtureBroadcasts.fixture`,
        ].join(`,`),
        filter: {
          "teamId": this.team.id,
          "fixture.datetime": this.$dayjs().add(1, `day`)
            .format(`YYYY-MM-DD`), // Now
        },
        filterOptions: {
          "fixture.datetime": `lessThanEqualTo`,
        },
        paginate: 1,
        sort: {
          "fixture.datetime": `desc`,
        },
      };

      const response = await this.$getStats(`fixtureTeam`, params);
      if (response !== null) {
        this.latestResult = response.data.length
          ? response.data[0].fixture
          : null;
        this.checkResult(this.latestResult);
      }
    },
    checkResult (fixture) {
      // DEBUG: Force fixture live state
      /*
      this.displayType = `live`;
      this.activeFixture = fixture;
      */

      // Check for non fixture/result states
      if (this.isFixtureLive(fixture)) {
        this.displayType = `live`;
        this.activeFixture = fixture;
        this.refresh = true;
        return;
      } else if (this.isFixturePostponed(fixture) && fixture.datetime > this.$dayjs().format(`YYYY-MM-DDTHH:mm`)) {
        this.displayType = `result`; // Will use result component
        this.postponed = true;
        this.refresh = false;
        this.activeFixture = fixture;
        return;
      } else if (this.isFixtureAbandoned(fixture)) {
        this.displayType = `result`;
        this.abandoned = true;
        this.activeFixture = fixture;
        this.refresh = false;
        return;
      } else if (this.isFixtureInFuture(fixture)) {
        this.displayType = `fixture`;
        this.activeFixture = fixture;
        return;
      }
      // Get fixture rollover time via WP config
      const resultEndDate = this.displayAllDay
        ? this.$dayjs(fixture.datetime)
          .add(1, `day`) // Midnight next day
          .set(`hour`, 0)
          .set(`minute`, 0)
          .set(`second`, 0)
        : this.$dayjs(fixture.datetime).add(this.resultDisplayTime, `minutes`); // time + specified minutes

      const now = this.$dayjs();

      // If result is before end time, set to result, otherwise get fixture
      if (now.isBefore(resultEndDate) && now.isAfter(fixture.datetime)) {
        this.displayType = `result`;
        this.activeFixture = fixture;
        this.refresh = false;
      } else {
        this.getNextFixture();
      }
    },
    async getNextFixture () {
      const params = {
        include: [
          `fixture.stage.season.competition`,
          `fixture.venue.venueNames`,
          `fixture.state`,
          `fixture.annotations`,
          `fixture.fixtureTeams.team.images`,
          `fixture.socialLinks.socialType`,
        ].join(`,`),
        filter: {
          "teamId": this.team.id,
          // TEMP FIX 2023/01/18: need to add a day otherwise it won't pull most recent fixture until the actual start time
          "fixture.datetime": this.$dayjs().format(`YYYY-MM-DDTHH:mm`), // Now
        },
        filterOptions: {
          "fixture.datetime": `greaterThan`,
        },
        paginate: 2,
        sort: {
          "fixture.datetime": `asc`,
        },
      };

      const response = await this.$getStats(`fixtureTeam`, params);
      if (response !== null) {
        if (this.isFixturePostponed(response.data[0].fixture)) {
          this.activeFixture = response.data.length
            ? response.data[1].fixture
            : null;
          this.displayType = `fixture`;
          this.refresh = true;
        } else {
          this.activeFixture = response.data.length
            ? response.data[0].fixture
            : null;
          this.displayType = `fixture`;
          this.refresh = true;
        }
      }
    },
  },
};
