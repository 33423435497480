
export default {
  props: {
    eventInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tags () {
      // convert string of tags into list of strings
      return this.eventInfo.tags.split(/\s*,\s*/g).filter(s => s !== ``);
    },
    date () {
      return `${this.getDate(this.eventInfo.datetime)}, ${this.getLocalTime(this.eventInfo.datetime)}`;
    },
  },
};

