
export default {
  props: {
    eventInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    externalButton () {
      return this.eventInfo.link_button ?? { display: false, url: ``, text: `` };
    },
    ticketButton () {
      return this.eventInfo.ticket_button ?? { display: false, url: ``, text: `` };
    },
  }
};
