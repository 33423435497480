import { render, staticRenderFns } from "./NextMatch.vue?vue&type=template&id=5f14b292&scoped=true&"
import script from "./NextMatch.vue?vue&type=script&lang=js&"
export * from "./NextMatch.vue?vue&type=script&lang=js&"
import style0 from "./NextMatch.vue?vue&type=style&index=0&id=5f14b292&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f14b292",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NextMatchEvent: require('/src/components/MatchComponents/NextMatch/NextMatchEvent.vue').default})
