
export default {
  props: {
    datetime: {
      type: String,
      required: true,
    },
    title: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      timeRemaining: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timeRetrieved: false,
      isPast: false,
      countdownInterval: null,
    };
  },
  watch: {
    isPast () {
      if (this.isPast) {
        clearInterval(this.countdownInterval);
      }
    },
  },
  mounted () {
    this.countdownInterval = setInterval(() => {
      this.getTimeRemaining();
    }, 1000);
  },
  beforeDestroy () {
    clearInterval(this.countdownInterval);
  },
  methods: {
    getTimeRemaining () {
      // Get both times in UTC
      const now = this.$dayjs().utc();
      const fixtureDate = this.$dayjs(this.datetime);
      let totalTime = fixtureDate.diff(now, `second`); // Total duration in lowest unit

      // If kickoff was in the past (i.e. a live match), set to 00:00:00:00
      if (fixtureDate.isBefore(now)) {
        this.timeRemaining = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        this.isPast = true;
      } else {
        // Calculate countdown (because dayjs doesn't have do it for us)
        const seconds = totalTime % 60;
        totalTime -= seconds;
        const minutes = (totalTime / 60) % 60;
        totalTime -= (minutes * 60);
        const hours = (totalTime / 3600) % 24;
        totalTime -= (hours * 3600);
        const days = totalTime / 86400;

        this.timeRemaining = {
          days,
          hours,
          minutes,
          seconds,
        };
      }

      this.timeRetrieved = true;
    },
  },
};
