import { render, staticRenderFns } from "./NextMatchEvent.vue?vue&type=template&id=a2187ce2&scoped=true&"
import script from "./NextMatchEvent.vue?vue&type=script&lang=js&"
export * from "./NextMatchEvent.vue?vue&type=script&lang=js&"
import style0 from "./NextMatchEvent.vue?vue&type=style&index=0&id=a2187ce2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2187ce2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/src/components/Content/ResponsiveImage.vue').default,MatchCountdown: require('/src/components/MatchComponents/MatchCountdown.vue').default,EventButtons: require('/src/components/Fixtures/Card/Buttons/EventButtons.vue').default})
